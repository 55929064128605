import { Component, Prop } from 'vue-property-decorator'
import { QDialog } from 'quasar'

import TermlyMixin from './mixins/termly'

import TermsContent from './TermsContent.vue'
import PrivacyPolicyContent from './PrivacyPolicyContent.vue'

@Component({
  components: {
    TermsContent,
    PrivacyPolicyContent
  }
})
export default class TermlyDialog extends TermlyMixin {
  @Prop({ type: String}) readonly contentId!: string

  get dialog (): QDialog {
    return this.$refs.termlyDlg as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.$emit('ok')
    this.hide()
  }
}
