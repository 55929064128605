import { render, staticRenderFns } from "./PrivacyPolicyContent.vue?vue&type=template&id=2c31c2ba"
import script from "./PrivacyPolicyContent.ts?vue&type=script&lang=ts&external"
export * from "./PrivacyPolicyContent.ts?vue&type=script&lang=ts&external"
import style0 from "./PrivacyPolicyContent.sass?vue&type=style&index=0&prod&lang=sass&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports