import { Component } from 'vue-property-decorator'
import {
  AUTH_LOGOUT_REQUEST,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AuthServiceError,
  AuthServiceInterface,
  CommonBaseMixin,
  ConfigService,
  inject,
  popupCenter,
  SHOW_MESSAGE_DIALOG, UserServiceInterface
} from 'booksprout-app'
import AlreadyHaveAnAccount from '../pages/signup/components/AlreadyHaveAnAccount.vue'
import NeedAnAccount from '../pages/signup/components/NeedAnAccount.vue'
import { BsInput } from '../../../components/bs-component-bundle'
import { ComponentState } from '../../../components/bs-component-bundle/mixins/common'
import TermlyDialog from '../../../components/TermlyDialog.vue'
import { LoginResultDto, UserDto, validStr } from 'booksprout'
import { Plugins } from '@capacitor/core'
import { uuid4 } from '@capacitor/core/dist/esm/util'
import {
  FacebookLogin, FacebookLoginResponse
} from '@capacitor-community/facebook-login'

const IsFreeSignupKey = 'isFreeSignUp'
@Component({
  components: {
    AlreadyHaveAnAccount,
    NeedAnAccount
  }
})
export default class BaseSignUpMixin extends CommonBaseMixin {
  agreeToTerms = false
  agreeToMarketing = false
  email = ''
  confirmEmail = ''
  password = ''
  confirmPassword = ''
  mode = ''
  keepMeLoggedIn: boolean = true

  @inject('AuthService')
  authService!: AuthServiceInterface

  @inject('ConfigService')
  configService!: ConfigService

  @inject('UserService')
  userService!: UserServiceInterface<UserDto>

  goToStage (stage: string, tab?: string) {
    this.$emit('click')

    if (tab != null) {
      void this.$router.push(this.linkService.frontEndRelative('sign-up', stage, tab))
    } else {
      void this.$router.push(this.linkService.frontEndRelative('sign-up', stage))
    }
  }

  get isFreeSignUp () {
    return this.$q.localStorage.has(IsFreeSignupKey)
  }

  get loginPayload () {
    return {
      emailAddress: this.email.trim(),
      password: this.password,
      extendLogin: this.keepMeLoggedIn
    }
  }

  addLeadToCampaign () {
    // First Promoter stuff
    // doing it here so all login methods are covered
    if (this.getLocalStorage('fp_ref')) {
      const today = new Date()
      const twoDaysAgo = new Date(today.setDate(today.getDate() - 2))

      if (new Date(this.authenticatedUser.createdDate)?.getTime() >= twoDaysAgo.getTime()) {
        void this.userService.addLeadToCampaign(this.getLocalStorage('fp_ref') as string)
          .then(() => {
            this.$q.localStorage.remove('fp_ref')
          })
          .catch((e) => {
            console.error('addLeadToCampaign error: ', e?.message)
          })
      }
    }
  }

  showOAuthTerms (loginResult: LoginResultDto, route: string, cb: () => void) {
    if (loginResult?.oAuthRegistered || loginResult?.termsConsentNeeded) {
      this.$q.dialog({
        component: () => import('../../../components/AgreeToOAuthTermsDialog.vue'),
        parent: this
      }).onOk(() => {
        this.addLeadToCampaign()
        cb()
      }).onCancel(() => {
        this.userService.withdrawContent().then(() => {
          this.$store.dispatch(AUTH_LOGOUT_REQUEST).then(() => {
            void this.$router.push(route)
          })
        })
      })
    } else {
      this.addLeadToCampaign()
      cb()
    }
  }

  forwardAuthenticatedUser () {
    return this.$store.dispatch(AUTH_REQUEST, {
      emailAddress: this.email.trim(),
      password: this.password
    }).then(loginResult => {
      this.$q.loading.hide()
      this.showOAuthTerms(loginResult, 'sign-up', () => {
        this.$q.localStorage.remove(IsFreeSignupKey)
        if (this.isFreeSignUp) {
          this.authService.updateUserSelectedRole(this.MODULE_CONSTANTS.USER.ROLE_SELECTION.AUTHOR).then((loginResult: LoginResultDto) => {
            this.$store.dispatch(AUTH_SUCCESS, {
              loginResult
            }).then(() => {
              this.onSuccessfulLogin(loginResult)
            }).catch(() => {
              // action rejected, do nothing
            })
          })
        } else {
          if (this.mimicOrAuthenticatedUser?.userEmails?.some(userEmail => userEmail.status === this.MODULE_CONSTANTS.USER.EMAILS.STATUS.VERIFIED)) {
            void this.$router.push('/sign-up/select-role').catch(() => {
              // do nothing, routeGuard redirected user
              // fixes "Uncaught (in promise) undefined" error
            })
          } else {
            void this.$router.push('/sign-up/verify-email').catch(() => {
              // do nothing, routeGuard redirected user
              // fixes "Uncaught (in promise) undefined" error
            })
          }
        }

      })
    }).catch((e: AuthServiceError) => {
      this.showError(e.message)
      this.$q.loading.hide()
    })
  }

  onSuccessfulLogin (loginResult: LoginResultDto, showAuthTermsDialog = true) {
    if (this.isMobileApp) {
      if (this.$q.localStorage.has('notificationRegistrationToken')) {
        const storedNotificationRegistrationToken = this.$q.localStorage.getItem<string>('notificationRegistrationToken')
        if (validStr(storedNotificationRegistrationToken + '')) {
          void this.userService.update({
            id: this.mimicOrAuthenticatedUser.id,
            notificationRegistrationToken: storedNotificationRegistrationToken + ''
          })
        }
      }

      // set local storage key, so we don't show mobile app onboarding next time
      this.setLocalStorage('mobileAppOnboardingDone', true)
      // close currently open dialog
      this.$emit('click')
    }

    /**
     * 1. If they came from a page that required them to login, send them back to that page
     * 2. If they are an author, send them to the author side ARC list page.
     * 3. If they are a reader, send them to the Find ARCs page on the reader side.
     */
    const query = this.$route.query.forward as string
    if (query !== void 0) {
      this.showOAuthTerms(loginResult, 'login', () => {
        void this.$router.push(query).catch(() => {
          // do nothing, routeGuard redirected user
          // fixes "Uncaught (in promise) undefined" error
        })
      })
    } else {
      if (!this.mimicOrAuthenticatedUser.isAdmin) {
        const doRedirect = () => {
          // don't redirect user if they logged in using AuthExpiredDialog
          if (this.mode === 'in-app-login') {
            return
          }

          // https://app.shortcut.com/booksprout/story/7330/bs-all-facebook-login-didn-t-ask-me-which-account-type-i-wanted
          if (this.mimicOrAuthenticatedUser?.reviewerRole === this.MODULE_CONSTANTS.USER.ROLES.REVIEWER.UNSET) {
            void this.$router.push('/sign-up/select-role').catch(() => {
              // do nothing, routeGuard redirected user
              // fixes "Uncaught (in promise) undefined" error
            })
          } else {
            if (validStr(this.mimicOrAuthenticatedUser.authorRole)) {
              // https://app.shortcut.com/booksprout/story/9119/app-wrong-page-after-login
              if (this.isMobileApp) {
                this.navigateToOtherApp('reviewer')
              } else {
                this.navigateToOtherApp('publisher')
              }
            } else {
              this.navigateToOtherApp('reviewer')
            }
          }
        }

        if (showAuthTermsDialog) {
          this.showOAuthTerms(loginResult, 'login', doRedirect)
        } else {
          doRedirect()
        }
      } else {
        void this.$router.push(this.linkService.homeUrl)
      }
    }
  }

  login () {
    this.$q.loading.show()
    return this.$store.dispatch(AUTH_REQUEST, this.loginPayload).then(loginResult => {
      this.$q.loading.hide()
      this.onSuccessfulLogin(loginResult)
    }).catch((e: AuthServiceError) => {
      this.$q.loading.hide()
      if (e) {
        void this.$store.dispatch(SHOW_MESSAGE_DIALOG, {
          error: true,
          message: e.message
        })
      }
    })
  }

  doOAuthLogin (type: string) {
    if (!this.isMobileApp) {
      const popupWindow = popupCenter({
        url: this.configService.config.axios.url + `auth/${type}`,
        title: `${type[0].toUpperCase() + type.substring(1)} Login`,
        h: 400,
        w: 980
      })
      let oAuthInterval: any = -1

      const doOnAuth = (event: any) => {
        // we are checking type here in case user started one social login and moved to another
        // i.e. clicked amazon, then cancelled, then clicked facebook
        if (event.data.eventName === 'booksprout-oauth' && event.data.loginType === type) {
          const loginResult = event.data.loginResult
          if (loginResult) {
            if (loginResult.message?.errorCode) {
              this.showError(this.$tc('system.errors.' + loginResult.message.errorCode))
            } else {
              this.$store.dispatch(AUTH_SUCCESS, { loginResult }).then(() => {
                this.onSuccessfulLogin(loginResult)
              }).catch(() => {
                // action rejected, do nothing
              })
            }
          }
          // Remove our listener to avoid memory leaks
          window.removeEventListener('message', doOnAuth)
          clearInterval(oAuthInterval)
          popupWindow?.close()
        }
      }

      // Listen for the response from the server via api/src/auth/static/redirect-oauth.html
      window.addEventListener('message', doOnAuth)
      // fall back to local storage if message event doesn't fire
      oAuthInterval = setInterval(() => {
        if (localStorage.getItem('oAuth_token')) {
          doOnAuth({
            data: {
              eventName: 'booksprout-oauth',
              loginType: type,
              loginResult: JSON.parse(localStorage.getItem('oAuth_token') || '{}')
            }
          })
          localStorage.removeItem('oAuth_token')
        }
      }, 1000)
    } else {
      if (type === 'facebook') {
        this.doFacebookMobileLogin()
      } else if (type === 'amazon') {
        this.doAmazonMobileLogin()
      } else if (type === 'apple') {
        this.doAppleLogin()
      }
    }
    return false
  }

  async doFacebookMobileLogin () {
    this.$q.loading.show()
    // await FacebookLogin.initialize({ appId: '1450667108644518' })
    await FacebookLogin.login({ permissions: ['public_profile', 'email'] }).then((userData: FacebookLoginResponse) => {
      this.authService.validateToken(
        userData.accessToken?.token || '',
        this.MODULE_CONSTANTS.AUTH.LOGIN_TYPE.FACEBOOK,
        this.MODULE_CONSTANTS.APP.SOURCE.REVIEWER
      ).then(loginResult => {
        if (loginResult) {
          // @ts-ignore
          if (loginResult.message?.errorCode) {
            this.showError(this.$tc('system.errors.' + (loginResult as any).message.errorCode))
          } else {
            this.$store.dispatch(AUTH_SUCCESS, {
              loginResult
            }).then(() => {
              this.onSuccessfulLogin(loginResult)
            }).catch(() => {
              // AUTH_SUCCESS action rejected
              // don't show anything to user
            }).finally(() => {
              this.$q.loading.hide()
            })
          }
        } else {
          // no login result, so show error
          this.showError(this.$tc('system.errors.somethingWentWrong'))
        }
      }).catch((e: any) => {
        this.$q.loading.hide()
        this.showError(e.message)
      })
    }).catch((e: any) => {
      this.$q.loading.hide()
      this.showError(e.message)
    })
  }

  doAmazonMobileLogin () {
    this.$q.loading.show()

    const USERID = 0x00010
    const PROFILE = 0x00020
    const options = {
      scopeFlag: USERID | PROFILE,
    }

    // @ts-ignore
    window.AmazonLoginPlugin.authorize(options, (r: any) => {
      this.authService.validateToken(
        r.accessToken,
        this.MODULE_CONSTANTS.AUTH.LOGIN_TYPE.AMAZON,
        this.MODULE_CONSTANTS.APP.SOURCE.REVIEWER
      ).then(loginResult => {
        if (loginResult) {
          // @ts-ignore
          if (loginResult.message?.errorCode) {
            this.showError(this.$tc('system.errors.' + (loginResult as any).message.errorCode))
          } else {
            this.$store.dispatch(AUTH_SUCCESS, {
              loginResult
            }).then(() => {
              this.onSuccessfulLogin(loginResult)
            }).catch(() => {
              // AUTH_SUCCESS action rejected
              // don't show anything to user
            }).finally(() => {
              this.$q.loading.hide()
            })
          }
        } else {
          // no login result, so show error
          this.showError(this.$tc('system.errors.somethingWentWrong'))
        }
      }).catch((e: any) => {
        this.$q.loading.hide()
        this.showError(e?.message || e)
      })
    }, (e: any) => {
      this.showError(e.message)
      this.$q.loading.hide()
    })
  }

  doAppleLogin () {
    const { SignInWithApple } = Plugins
    if (!SignInWithApple || typeof SignInWithApple.authorize !== 'function') {
      return this.showError('Sign in with Apple unavailable at this time.')
    }

    this.$q.loading.show()
    const nonce = uuid4()
    SignInWithApple.authorize({
      clientId: 'co.booksprout.reviewer',
      nonce
    }).then(async (res: any) => {
      this.authService.validateToken(
        res.response.identityToken,
        this.MODULE_CONSTANTS.AUTH.LOGIN_TYPE.APPLE,
        this.MODULE_CONSTANTS.APP.SOURCE.REVIEWER,
        nonce
      ).then(loginResult => {
        if (loginResult) {
          // @ts-ignore
          if (loginResult.message?.errorCode) {
            this.showError(this.$tc('system.errors.' + (loginResult as any).message.errorCode))
          } else {
            this.$store.dispatch(AUTH_SUCCESS, {
              loginResult
            }).then(() => {
              this.onSuccessfulLogin(loginResult)
            }).catch(() => {
              // AUTH_SUCCESS action rejected
              // don't show anything to user
            }).finally(() => {
              this.$q.loading.hide()
            })
          }
        } else {
          // no login result, so show error
          this.showError(this.$tc('system.errors.somethingWentWrong'))
        }
      }).catch((e: any) => {
        this.showError(e.message)
      })
    }).catch((e: any) => {
      this.$q.loading.hide()
      this.showError(e.message)
    }).finally(() => {
      this.$q.loading.hide()
    })
  }

  async emailSignUp () {
    this.$q.loading.show()
    return this.authService.signUp(
      this.email.trim(),
      this.password,
      this.agreeToMarketing,
      String(this.$route.query.forward || '')
    ).then(() => {
      return this.forwardAuthenticatedUser()
    }).catch(async (e: any) => {
      this.$q.loading.hide()
      // If the user email already exists try and log them in with the email / password combo they've entered.
      if (e.message === this.$tc('system.errors.emailExists')) {
        const loginResult = await this.authService.login(this.email.trim(), this.password, this.keepMeLoggedIn, true).catch(() => {
          // Login failed so just show them the "email already exists message"
          ;(this.$refs.email as BsInput).componentState = ComponentState.Invalid
          ;(this.$refs.email as BsInput).errorMessage = e.message
        })

        if (loginResult) {
          this.$store.dispatch(AUTH_SUCCESS, {
            loginResult
          }).then(() => {
            this.onSuccessfulLogin(loginResult)
          }).catch(() => {
            // action rejected, do nothing
          })
        }
      } else if (e.message === this.$tc('system.errors.errorSendingWelcomeEmail')) {
        await this.forwardAuthenticatedUser()
        this.showError(e.message)
      } else {
        this.showError(e.message)
      }
    })
  }

  openTermsAndConditions () {
    this.$q.dialog({
      component: TermlyDialog,
      parent: this,
      contentId: 'terms'
    })
  }

  openPrivacyPolicy () {
    this.$q.dialog({
      component: TermlyDialog,
      parent: this,
      contentId: 'privacy-policy'
    })
  }

  mounted () {
    this.$q.localStorage.set(IsFreeSignupKey, !!this.$route.query.free)
  }
}
