import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from '../../../../../components/mixins/base'

@Component
export default class AlreadyHaveAnAccount extends CommonBaseMixin {
  onLoginClick () {
    this.$emit('click')
    void this.$router.push('/login').catch(() => {
      // do nothing, routeGuard redirected user
      // fixes "Uncaught (in promise) undefined" error
    })
  }
}
